import React, { useEffect, useState } from "react";
import Singletextbutton from "../atoms/Singletextbutton";
import "./Hero.css";
import Singlementorcard from "../Singlementorcard/Singlementorcard";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { MentorHeroSectionData } from "../../home-page-sample-data";
import { useHistory } from "react-router-dom";

const Hero = () => {
  const [mentorData, setMentorData] = useState([]);

  const history = useHistory();

  const redirectToMentorSearch = () => {
    history.push("/mentors");
  };

  useEffect(() => {
    setMentorData(MentorHeroSectionData);
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  // Navigate to the previous mentor
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? mentorData.length - 1 : prevIndex - 1
    );
  };

  // Navigate to the next mentor
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === mentorData.length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <div className="HeroComponent">
      <div className="Introduction-content">
        <div className="page-main-heading">
          Find the right career mentors to fast-track your success
        </div>

        <div className="component-sub-heading-first-line textAlignment">
          Discover the perfect mentor for career guidance and get endorsed by
          the top industry professionals
        </div>

        <Singletextbutton
          content={"Connect with a mentor now"}
          onClick={redirectToMentorSearch}
        />
      </div>

      <div className="HeroComponentCardSection">
        {mentorData?.map((element, index) => {
          const isActive = index === currentIndex; // Check if the card is active
          return (
            <>
              <div
                className={`SingleMentorInfo ${
                  isActive ? "activeInfo" : "hiddenInfo"
                }`}
                key={index}
              >
                <div className="MentorCardSection">
                  <Singlementorcard element={element} />
                </div>

                <div className="MentorReviewSection">
                  {element?.reviews?.map((review, reviewIndex) => (
                    <SingleReview {...review} key={reviewIndex} />
                  ))}
                </div>
              </div>
              {element?.reviews?.map((review, reviewIndex) => (
                <SingleReview {...review} key={reviewIndex} />
              ))}
            </>
          );
        })}

        {/* Arrow  */}
        <div className="NavigationArrowContainer">
          <div className="NavigationArrowBox" onClick={handlePrev}>
            <FaArrowLeft
              style={{
                height: "24px",
                width: "24px",
              }}
            />
          </div>
          <div className="NavigationArrowBox" onClick={handleNext}>
            <FaArrowRight
              style={{
                height: "24px",
                width: "24px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SingleReview = ({ star, comment, name, position }) => {
  return (
    <div className="SingleReviewContainer gradient-bg">
      <div className="reviewRating">
        {[...Array(5)].map((tempStar, index) => (
          <span key={index} className="star">
            {index < star ? (
              <IoIosStar
                style={{
                  color: "black",
                }}
              />
            ) : (
              <IoIosStarOutline
                style={{
                  color: "black",
                }}
              />
            )}
          </span>
        ))}
      </div>
      <div className="reviewComment">{comment}</div>

      <div className="reviewInfo">
        <div className="reviewerName">{name}</div>
        {/* <div className="reviewerPosition">{position}</div> */}
      </div>
    </div>
  );
};

export default Hero;
