import "./Mentoring.css";
import React, { useState } from "react";

import TabContent from "./components-ment/TabContent";
import { SlCalender } from "react-icons/sl";
import { UpcomingProvider } from "./context/UpcomingContext";
import { Helmet } from "react-helmet-async";

const Mentoring = () => {
  const [activeTab, setActiveTab] = useState("YourSessions");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <UpcomingProvider>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.unispade.com/user-dashboard/mentoring"
        />
      </Helmet>
      <div className="Ment">
        <div className="TabContainer">
          <div className="LeftTabs">
            <div
              className={`Tab ${activeTab === "YourSessions" ? "active1" : ""}`}
              onClick={() => handleTabClick("YourSessions")}
            >
              Your Sessions
            </div>
            <div
              className={`Tab ${activeTab === "Requests" ? "active1" : ""}`}
              onClick={() => handleTabClick("Requests")}
            >
              Requests
            </div>
            <div
              className={`Tab ${activeTab === "Upcoming" ? "active1" : ""}`}
              onClick={() => handleTabClick("Upcoming")}
            >
              Upcoming
            </div>
            <div
              className={`Tab ${activeTab === "History" ? "active1" : ""}`}
              onClick={() => handleTabClick("History")}
            >
              History
            </div>
          </div>
          <div
            className={`Tab RightTab ${
              activeTab === "YourAvailability" ? "active1" : ""
            }`}
            onClick={() => handleTabClick("YourAvailability")}
          >
            <div className="availability-icon">
              <SlCalender />
              Your Availability
            </div>
          </div>
        </div>

        <TabContent activeTab={activeTab} />
      </div>
    </UpcomingProvider>
  );
};

export default Mentoring;
