import Faqs from "./compoments/Faqs/Faqs";
import Hero from "./compoments/Hero/Hero";
import Howitworks from "./compoments/Howitworks/Howitworks";
import Knowledgesection from "./compoments/Knowledgesection/Knowledgesection";
import Mentorlineup from "./compoments/Mentorlineup/Mentorlineup";
import Unispadeadvantage from "./compoments/Unispadeadvantage/Unispadeadvantage";
import { Helmet } from "react-helmet-async";

import "./NewHomePage.css";

function NewHomePage() {
  return (
    <div className="NewHomPage">
      <Helmet>
        <title>Connecting Career Development Mentors | Leading Mentorship Platform | Unispade</title>
        <meta name="description" content="Connect with industry experts in Product Management, Digital Marketing, Human Resources and more. Book 1-on-1 coaching sessions online and achieve your career goals today!" />
        <link rel="canonical" href="https://unispade.com/" />
      </Helmet>
      <Hero />
      <Howitworks />
      <Mentorlineup />
      <Unispadeadvantage />
      {/* <Knowledgesection /> */}
      <Faqs />
    </div>
  );
}

export default NewHomePage;
