import React from "react";
import "./WhyChooseUnispade.css";
import Singletextbutton from "../atoms/Singletextbutton";
import { useHistory } from "react-router-dom";

const reasons = [
  {
    reasonHeading: "Navigate Complex Product Ecosystems",
    reasonDesc:
      "Learn to balance user needs, business goals, and technical constraints from seasoned professionals.",
  },
  {
    reasonHeading: "Develop Strategic Thinking",
    reasonDesc:
      "Hone your ability to create and execute product roadmaps that drive growth.",
  },
  {
    reasonHeading: "Master Stakeholder Management",
    reasonDesc:
      "Gain insights on effectively collaborating with cross-functional teams.",
  },
  {
    reasonHeading: "Accelerate Your Career Growth",
    reasonDesc: "Get insider tips on advancing to senior product roles.",
  },
];

const WhyChooseUnispade = () => {
  const history = useHistory();

  const redirectToMentorSearch = () => {
    history.push("/mentors");
  };
  return (
    <div className="WhyChooseUnispadeMentor">
      <div className="second-main-heading">
        Choose Unispade for Product Management mentorship
      </div>

      <div className="ReasonsList">
        {reasons?.map((element, index) => {
          return (
            <div className="SingleReasoningContainer" key={index}>
              <div className="component-sub-heading-first-line">
                {element.reasonHeading}
              </div>

              <div className="component-heading-description-light">
                {element.reasonDesc}
              </div>
            </div>
          );
        })}
      </div>

      <Singletextbutton
        content={"Explore All Product Management Mentors"}
        onClick={redirectToMentorSearch}
      />
    </div>
  );
};

export default WhyChooseUnispade;
