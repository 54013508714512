import React, { useState } from "react";
import "./Settings.css";
import { Helmet } from "react-helmet-async";

const Setting = ({ updatePassword }) => {
  const resetPasswordFormInit = {
    CurrentPassword: "",
    password: "",
    confirmPassword: "",
  };

  const [resetPasswordForm, setResetPasswordForm] = useState(
    resetPasswordFormInit
  );

  const PasswordSet = [
    {
      Tittle: "Current Password",
      placeHolder: "Enter your current password",
      name: "CurrentPassword",
    },

    {
      Tittle: "Create New Password",
      placeHolder: "Set a secure password",
      name: "password",
    },
    {
      Tittle: "RE-ENTER PASSWORD*",
      placeHolder: "Confirm your password",
      name: "confirmPassword",
    },
  ];
  const handleChange = (event) => {
    const { name, value } = event.target;
    let resetPasswordFormCopy = { ...resetPasswordForm };
    resetPasswordFormCopy[name] = value;
    setResetPasswordForm(resetPasswordFormCopy);
  };

  const onUpdate = (event) => {
    updatePassword(resetPasswordForm);
    event.preventDefault();
  };
  return (
    <main className="settings_container">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.unispade.com/user-dashboard/setting"
        />
      </Helmet>
      <form className="setting BasicInfo">
        <div className="Fields">
          <h6>Reset password</h6>
          <div className="passwordField">
            {PasswordSet.map((key) => (
              <div className="InfoDiv">
                <p>{key.Tittle}</p>
                <span>{key.subtitle}</span>
                <input
                  placeholder={key.placeHolder}
                  name={key.name}
                  type="password"
                  onChange={handleChange}
                  value={resetPasswordForm[key.name]}
                />
              </div>
            ))}
          </div>
        </div>

        {/* <div className="buttons">
            <button onClick={(event)=>{ setResetPasswordForm(resetPasswordFormInit);event.preventDefault();}}>Cancel</button>
            <button onClick={onUpdate}>Save</button>
          </div> */}
        {/* <div className='setting_form_buttons'>
          <button onClick={(event) => { setResetPasswordForm(resetPasswordFormInit); event.preventDefault(); }} className='btn'>Cancel</button>
          <button onClick={onUpdate} type='submit' className='btn'>Save</button>
        </div> */}
      </form>
      <div className="formButtons">
        <div className="innerFormContainer">
          <button
            onClick={(event) => {
              setResetPasswordForm(resetPasswordFormInit);
              event.preventDefault();
            }}
            className="formBtn"
          >
            Cancel
          </button>
          <button onClick={onUpdate} type="submit" className="formBtn">
            Save
          </button>
        </div>
      </div>
    </main>
  );
};

export default Setting;
