import React from "react";
import "./Howitworks.css";
import person_search from "../../Images/person_search.png";
import confirmation_number from "../../Images/confirmation_number.png";
import reviews from "../../Images/reviews.png";
import thumb_up from "../../Images/thumb_up.png";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";

const StepsData = [
  {
    image: person_search,
    stepHeading: "Find your mentor",
    stepDesc:
      "Browse our curated list of top-tier mentors across various industries and expertise.",
    sessions: [
      { _id: "1", session_name: "Product Management Mentors" },
      { _id: "2", session_name: "Marketing Mentors" },
      { _id: "3", session_name: "Design Mentors" },
      { _id: "4", session_name: "Tech Mentors" },
    ],
  },
  {
    image: confirmation_number,
    stepHeading: "Book a session",
    stepDesc:
      "Schedule a personalized call with your chosen mentor at a time that suits you.",
    sessions: [
      { _id: "1", session_name: "1st Training Consultation" },
      { _id: "2", session_name: "Resume Review" },
      { _id: "3", session_name: "Mock Interviews" },
      { _id: "4", session_name: "Career Guidance" },
    ],
  },
  {
    image: reviews,
    stepHeading: "Gain valuable insights",
    stepDesc:
      "Learn from your mentor's experience and receive tailored advice that suits your career path.",
    sessions: [
      { _id: "1", session_name: "Personalized Doubt Resolution" },
      { _id: "2", session_name: "Personalized Insights" },
      { _id: "3", session_name: "Customized Resume Building" },
      { _id: "4", session_name: "Career Journey Mapping" },
    ],
  },
  {
    image: thumb_up,
    stepHeading: "Get endorsed",
    stepDesc:
      "Stand out to recruiters with mentor endorsements and put your job search on autopilot.",
    sessions: [
      { _id: "1", session_name: "Talent Pool of Marketers" },
      { _id: "2", session_name: "Talent Pool of Product Managers" },
      { _id: "3", session_name: "Talent Pool of Software Engineers" },
      { _id: "4", session_name: "Talent Pool of Designers" },
    ],
  },
];

const Howitworks = () => {
  return (
    <div className="HowitworksContainer">
      <div className="second-main-heading">How it works</div>
      <div className="EntireRoadmap">
        {StepsData?.map((element, index) => {
          return (
            <div className="EachSingleStep" key={index}>
              <div className="SingleStepHeadingIcon gradient-bg">
                <img
                  src={element?.image}
                  alt="Person Search"
                  style={{
                    width: "34px",
                    height: "34px",
                  }}
                />
              </div>
              <div className="StepInfoAndRedirects">
                <div className="StepInfoOnly">
                  <div
                    className="component-heading-description-dark"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {element?.stepHeading}
                  </div>
                  <div className="component-heading-description-light">
                    {element?.stepDesc}
                  </div>
                </div>

                <div className="StepRedirectsOnly">
                  {element?.sessions?.map((session) => {
                    return (
                      <TwoButtonWrapper
                        firstComponent={session?.session_name}
                        color={"black"}
                        height={"40px"}
                        hoverActivate={false}
                        background={"var(--global-gradient"}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Howitworks;
