import React, { useState } from "react";
import { IoFunnelOutline } from "react-icons/io5";
import { MdExpandMore, MdOutlineDone } from "react-icons/md";
import "./Activity.css";
import UserInfoSlate from "../../../atoms/UserInfoSlate/UserInfoSlate";
import TwoButtonWrapper from "../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { RxCross2 } from "react-icons/rx";
import linkedLogoUrl from "../img/linkedInLogo.png";
import { CiChat1 } from "react-icons/ci";
import { MdDoneAll, MdOutlineWavingHand } from "react-icons/md";
import { ExternalActivity } from "./ExternalActivity/ExternalActivity";
import { InternalActivity } from "./InternalActivity/InternalActivity";
import { ApplicationActivity } from "./ApplicationActivity/ApplicationActivity";
import { Helmet } from "react-helmet-async";
const Activity = () => {
  const [selectedActivityTab, setSelectedActivityTab] = useState("external");
  const activityTabOptions = [
    {
      name: "External",
      key: "external",
    },
    {
      name: "Internal",
      key: "internal",
    },
    {
      name: "Talent Pool Applications & Invitations",
      key: "talentPoolApplicationAndInvitations",
    },
  ];

  const handleActivityTab = (key) => {
    setSelectedActivityTab(key);
    console.log("activity tab clicked", key);
  };
  return (
    <div className="userActivityPage">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.unispade.com/user-dashboard/activity"
        />
      </Helmet>
      <div className="ActivityTabs">
        {activityTabOptions?.map((tab, index) => {
          return (
            <div
              className={`flexBetweenRadius10 ${
                tab?.key === selectedActivityTab ? "" : "deactiveTab"
              }`}
              key={index}
              onClick={() => handleActivityTab(tab?.key)}
            >
              {tab?.name}
            </div>
          );
        })}
      </div>

      {selectedActivityTab === "external" ? (
        <ExternalActivity />
      ) : selectedActivityTab === "internal" ? (
        <InternalActivity />
      ) : selectedActivityTab === "talentPoolApplicationAndInvitations" ? (
        <ApplicationActivity />
      ) : null}
    </div>
  );
};

export default Activity;
