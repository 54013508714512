import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./UserProfile.css";
import FirstCompo from "../UserProfileCompo/firstUserProfileCompo/firstUserProfileCompo";
import SecondCompo from "../UserProfileCompo/secondUserProfileCompo/secondUserProfileCompo";
import UserSection from "../UserProfileCompo/userSection/userSection";
import CaseStudyCard from "../CaseStudyListingComponents/CaseStudyCard/CaseStudyCard";
import UserPageContent from "../UserProfileCompo/UserPageContent.js/userPageContent.js";
import axios from "../../axios";
import Loader from "../loader/Loader";
import { ApplyToTalentPoolPage } from "../UserProfileCompo/ApplyToTalentPoolPage/ApplyToTalentPoolPage.jsx";
import { toast } from "react-toastify";
import toastStyle from "../../assets/toastConfig.js";
import { Helmet } from "react-helmet-async";
const App = () => {
  const username = useParams().username;
  const [currentTab, setCurrentTab] = useState("");
  const [userDetails, setUserDetail] = useState();
  const [caseStudies, setCaseStudies] = useState();
  const [applyToTalentPoolPage, setApplyToTalentPoolPage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const userProfileDetails = await axios.get(
        `/marketers/getMarketerByUsername/${username}`
      );
      const creditsResponse = await axios.get(
        `/credits/getMarketersCredits?email=${userProfileDetails.data.email}`
      );
      if (userProfileDetails.data.success === false) {
        toast.error(userProfileDetails.data.message, toastStyle);
        console.log("The user does not exist");
        return;
      } else {
        setUserDetail(userProfileDetails.data.data);
        setCaseStudies(creditsResponse.data);
      }
    };
    fetchData();
    if (currentTab === "") {
      console.log("You are fetching data again");
      fetchData();
    }
  }, []);

  console.log("the current shit is ", currentTab);

  if (!userDetails && !caseStudies) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else {
    console.log(userDetails.name);
    return (
      <div className="Userprofilecompo">
        <Helmet>
          <link rel="canonical" href={`https://www.unispade.com/${username}`} />
        </Helmet>
        {/* <FirstCompo key={data._id} data={data} />
        <SecondCompo key={data._id} username={username} data={data} /> */}
        <UserSection
          userData={userDetails}
          setApplyToTalentPoolPage={setApplyToTalentPoolPage}
          currentTab={currentTab}
        />

        {applyToTalentPoolPage ? (
          <ApplyToTalentPoolPage
            user={userDetails}
            setApplyToTalentPoolPage={setApplyToTalentPoolPage}
          />
        ) : (
          <UserPageContent
            caseStudies={caseStudies}
            user={userDetails}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
          />
        )}
      </div>
    );
  }
};
export default App;
