import React, { useState } from "react";
import "./Faqs.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
const faqs = [
  {
    id: 1,
    faq_question:
      "How does Unispade ensure the quality of mentors on the platform?",
    faq_answer:
      "We implement a rigorous vetting process for all mentors. This includes verifying their professional experience, expertise, and track record in their respective fields. We only approve mentors who meet our high standards of knowledge and experience.",
  },
  {
    id: 2,
    faq_question: "How does the talent pool feature work?",
    faq_answer:
      "If a mentor believes you're a strong candidate, they may add you to their talent pool, potentially connecting you with exclusive job opportunities.",
  },
  {
    id: 3,
    faq_question:
      "What happens if a mentor doesn't show up for a scheduled session?",
    faq_answer:
      "We take attendance very seriously. In the rare event that a mentor doesn't show up, we immediately credit your account with Unispade Credits equivalent to the session value. These credits can be used to book another session with a different mentor of your choice, ensuring you still receive valuable guidance.",
  },
  {
    id: 4,
    faq_question: "How reliable is the video conferencing system?",
    faq_answer:
      "Our platform uses Google Meet integration to ensure smooth and reliable video conferencing. We've invested heavily in our infrastructure to minimize technical issues and provide a seamless experience for both mentors and mentees.",
  },
  {
    id: 5,
    faq_question: "How is pricing determined on Unispade?",
    faq_answer:
      "Mentors set their own rates based on their experience and expertise. We encourage fair and competitive pricing.",
  },
  {
    id: 6,
    faq_question: "How do I know if a mentor is right for me?",
    faq_answer:
      "Each mentor on Unispade has a detailed profile showcasing their experience, expertise, and areas of focus. We encourage you to review these carefully. You can also book shorter introductory sessions to ensure a good fit before committing to longer consultations.",
  },
  {
    id: 7,
    faq_question: "Is there a minimum or maximum session length?",
    faq_answer:
      "Session lengths can vary based on the mentor and the type of consultation. Generally, we offer options ranging from quick 15-minute check-ins to in-depth 60-minute sessions. Some mentors may also offer custom session lengths for specific needs.",
  },
  {
    id: 8,
    faq_question: "What kind of support does Unispade offer?",
    faq_answer:
      "We have a dedicated support team ready to assist with any questions or issues you may encounter. Whether it's technical support, scheduling concerns, or general inquiries, we're here to help ensure a positive experience on our platform.",
  },
  {
    id: 9,
    faq_question: "Are there any additional fees beyond the session cost?",
    faq_answer:
      "We believe in transparency. The price you see is the price you pay. There are no hidden fees or additional costs beyond the clearly stated session price.",
  },
  {
    id: 10,
    faq_question: "What are Unispade Credits and how do they work?",
    faq_answer:
      "Unispade Credits are our way of ensuring you always receive value from our platform. If a session is cancelled due to mentor absence, you receive credits equal to the session value. These credits can be used to book any other session on our platform, giving you flexibility and guaranteeing a positive experience.",
  },
];

const Faqs = () => {
  const [openQuestionId, setOpenQuestionId] = useState(null);

  const handleQuestionClick = (id) => {
    if (openQuestionId === id) {
      setOpenQuestionId(null);
    } else {
      setOpenQuestionId(id);
    }
  };
  return (
    <div className="FaqsContainer">
      <div className="second-main-heading">FAQs</div>
      <div className="FaqsList">
        {faqs?.map((element, index) => {
          return (
            <div className="FaqSingleSet">
              <div
                className="FaqQuestion"
                onClick={() => handleQuestionClick(element?.id)}
              >
                <div className="component-heading-description-darker">
                  {element.faq_question}
                </div>
                <div>
                  {openQuestionId === element.id ? (
                    <IoIosArrowUp />
                  ) : (
                    <IoIosArrowDown />
                  )}
                </div>
              </div>

              {openQuestionId === element?.id && (
                <div className="component-heading-description-light">
                  {element?.faq_answer}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faqs;
