import React, { useEffect, useState } from "react";
import "./Pools.css";
import { RxCross2 } from "react-icons/rx";
import { CiChat1 } from "react-icons/ci";
import {
  MdDoneAll,
  MdOutlineWavingHand,
  MdOutlineDone,
  MdExpandMore,
  MdDelete,
  MdExpandLess,
} from "react-icons/md";

import { IoFunnelOutline } from "react-icons/io5";
import UserProfile from "../../assets/profile.png";
import TwoButtonWrapper from "../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import axios from "../../axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet-async";

export const Pools = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [filteredShowData, setFilteredShowData] = useState([]);

  const history = useHistory();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/common/all-pools");
      setCategoryList(data.data);
      setFilteredShowData(data.data);
      setProvidedFilterOptions(data?.filters);
    })();
  }, []);

  const handleProfileRedirect = (username) => {
    history.push(`/userprofile/${username}/talentPool`);
  };

  const [providedFilterOptions, setProvidedFilterOptions] = useState({});
  const [profileDropdown, setProfileDropdown] = useState({});
  const [filterList, setFilterList] = useState([]);

  const [searchCompany, setSearchCompany] = useState("");
  const [searchPool, setSearchPool] = useState("");
  const [searchEndorser, setSearchEndorser] = useState("");

  const [filterSeachActivated, setFilterSearchActivated] = useState({});
  const [searchedFilterOptions, setSearchedFilterOptions] = useState([]);

  const handleFilterDropdown = (filter_name) => {
    setProfileDropdown({
      ...profileDropdown,
      [filter_name]: !profileDropdown[filter_name],
    });
  };

  const addFilter = (filterData, type) => {
    setFilterList((prevFilters) => [...prevFilters, { filterData, type }]);
    setProfileDropdown({});
    setFilterSearchActivated({});
    setSearchedFilterOptions([]);
    setSearchCompany("");
    setSearchPool("");
    setSearchEndorser("");
  };

  useEffect(() => {
    if (filterList.length === 0) {
      setFilteredShowData(categoryList);
    } else {
      const filteredData = applyFilters(categoryList, filterList);
      setFilteredShowData(filteredData);
    }
  }, [filterList]);

  const applyFilters = (categoryList, filterList) => {
    let filteredData = categoryList;
    filterList.forEach((filter) => {
      filteredData = filteredData.filter((pool) => {
        if (filter.type === "Company") {
          return pool.user_id.experience.some(
            (exp) => exp.companyName === filter.filterData
          );
        }
        if (filter.type === "PoolCategory") {
          return pool.name === filter.filterData;
        }
        if (filter.type === "Endorser") {
          return pool.user_id.name === filter.filterData;
        }
      });
    });

    return filteredData;
  };

  const removeFilter = (filter_name) => {
    setFilterList((prevFilters) =>
      prevFilters.filter((item) => item.filterData !== filter_name)
    );
  };

  const handleSearchFilter = (filter_value, type) => {
    if (type === "Company") {
      setSearchCompany(filter_value);
    }
    if (type === "PoolCategory") {
      setSearchPool(filter_value);
    }
    if (type === "Endorser") {
      setSearchEndorser(filter_value);
    }
    if (filter_value === "") {
      setFilterSearchActivated({});
      setSearchedFilterOptions([]);
      return;
    }

    setFilterSearchActivated({ [type]: true });
    const filterOptions = providedFilterOptions[type].filter((option) => {
      return option.toLowerCase().includes(filter_value.toLowerCase());
    });
    setSearchedFilterOptions([...filterOptions]);
  };
  return (
    <div className="PoolsPage">
      <Helmet>
        <title>Explore Talents for Career & Business Growth | Unispade</title>
        <meta name="description" content="Join mentorship pools to connect with expert mentors. Access personalized guidance for career development and business success with Unispade." />
        <link rel="canonical" href="https://unispade.com/pools" />
      </Helmet>
      <div className="filterSection">
        <div className="filterTab">
          <div className="filterIcon">
            <IoFunnelOutline />
            <p>Filtered By</p>
          </div>

          <div className="filteringOption">
            <div className="filterTabDropdown">
              <div>
                <input
                  type="text"
                  placeholder="Search Company"
                  className="filterSeachInput"
                  value={searchCompany}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "Company")
                  }
                />
              </div>
              <div onClick={() => handleFilterDropdown("Company")}>
                {profileDropdown["Company"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>

              {filterSeachActivated["Company"]
                ? searchedFilterOptions.length > 0 && (
                    <div className="variousOptions">
                      {searchedFilterOptions?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Company")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )
                : profileDropdown["Company"] && (
                    <div className="variousOptions">
                      {providedFilterOptions["Company"]?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Company")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )}
            </div>

            <div className="filterTabDropdown">
              <div>
                <input
                  type="text"
                  placeholder="Search Pool"
                  className="filterSeachInput"
                  value={searchPool}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "PoolCategory")
                  }
                />
              </div>
              <div onClick={() => handleFilterDropdown("PoolCategory")}>
                {profileDropdown["PoolCategory"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>

              {filterSeachActivated["PoolCategory"]
                ? searchedFilterOptions.length > 0 && (
                    <div className="variousOptions">
                      {searchedFilterOptions?.map((option) => {
                        return (
                          <div
                            onClick={() => addFilter(option, "PoolCategory")}
                          >
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )
                : profileDropdown["PoolCategory"] && (
                    <div className="variousOptions">
                      {providedFilterOptions["PoolCategory"]?.map((option) => {
                        return (
                          <div
                            onClick={() => addFilter(option, "PoolCategory")}
                          >
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )}
            </div>

            <div className="filterTabDropdown">
              <div>
                <input
                  type="text"
                  placeholder="Search Endorser"
                  className="filterSeachInput"
                  value={searchEndorser}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "Endorser")
                  }
                />
              </div>
              <div onClick={() => handleFilterDropdown("Endorser")}>
                {profileDropdown["Endorser"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>

              {filterSeachActivated["Endorser"]
                ? searchedFilterOptions.length > 0 && (
                    <div className="variousOptions">
                      {searchedFilterOptions?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Endorser")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )
                : profileDropdown["Endorser"] && (
                    <div className="variousOptions">
                      {providedFilterOptions["Endorser"]?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Endorser")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )}
            </div>
          </div>

          {filterList?.length > 0 && (
            <>
              <div></div>
              <div className="selectedFilters">
                {filterList?.map((filter) => {
                  return (
                    <div className={`singleFilter ${filter?.type}`}>
                      {filter?.filterData}
                      <RxCross2
                        className="crossIcon"
                        onClick={() => removeFilter(filter?.filterData)}
                      ></RxCross2>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="poolsPageContainer">
        {filteredShowData?.map((category) => {
          return (
            <div className="poolCard">
              <div className="poolNameInfo">
                <p className="poolName lighterFont">
                  {category?.name} | {category?.user_count}
                </p>
                <p className="poolPrice">{category?.price_per_user}/profile</p>
              </div>
              <p className="lighterFont">By</p>
              <TwoButtonWrapper
                firstComponent={
                  <img
                    src={category?.user_id?.profilePhoto}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                  />
                }
                secondComponent={category?.user_id?.name}
                backgroundColor={"white"}
                color={"black"}
                fontSize={"16px"}
                componentFunction={() =>
                  handleProfileRedirect(category?.user_id?.username)
                }
              />

              {category?.user_id?.experience.length > 0 && (
                <>
                  <p className="lighterFont">From</p>
                  <TwoButtonWrapper
                    firstComponent={
                      <img
                        src={category?.user_id?.experience[0].companyLogo}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      />
                    }
                    secondComponent={
                      category?.user_id?.experience[0].companyName
                    }
                    backgroundColor={"white"}
                    color={"black"}
                    fontSize={"16px"}
                  />
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
