import VikashProfile from "./Images/vikash.jpg";
import PrashamProfile from "./Images/prasham.jpg";
import NavcharanProfile from "./Images/navcharan.jpg";
import KishanProfile from "./Images/kishan.jpg";

export const MentorHeroSectionData = [
  {
    _id: 1,
    mentor: {
      name: "Prasham Mehta",
      linkedIn: "https://www.linkedin.com/in/prasham-mehta/",
      profilePhoto: PrashamProfile,
      experience: [
        { role: "Product Manager", companyName: "123 Stores" },
        { role: "Product Manager", companyName: "North Europe Assistance" },
      ],
      about:
        "With over five years of experience in e-commerce and automotive industries, I thrive on transforming complex challenges into streamlined solutions. Currently, I lead key initiatives...",
      username: "prasham1895",
    },
    sessions: [
      { _id: "1", session_name: "1:1 Session" },
      { _id: "2", session_name: "Mock Interview" },
      { _id: "3", session_name: "Career Guidance" },
    ],
    reviews: [
      {
        name: "Rahul sharma",
        comment:
          "Prashant's mock interviews were an absolute game changer! The insider tips and the difficult questions made me ready for my PM role.",
        star: 5,
        position: "Pruduct manager",
        company: null,
        linkedInUrl: null,
      },
      {
        name: "Priya Iyer",
        comment:
          "From a lost fresher to a confident product manager, all thanks to Prashant! His resume rebuilding and career plan were lifesavers. He is the mentor every PM aspirant needs!",
        star: 5,
        position: "Pruduct manager",
        company: null,
        linkedInUrl: null,
      },
    ],
  },
  {
    _id: 2,
    mentor: {
      name: "Navcharan Singh",
      linkedIn: "https://www.linkedin.com/in/navcharansinghbali/",
      profilePhoto: NavcharanProfile,
      experience: [
        { role: "Assistant Manager", companyName: "Times Network" },
        { role: "SEO Specialist", companyName: "Real Time Data Services" },
      ],
      about:
        "Experienced Digital Marketer with a history of working in the astrology, education & retail industry. Skilled in Search Engine Optimization (SEO), Google Ads, Google Analytics, and Email Marketing.",
      username: "Navcharan",
    },
    sessions: [
      { _id: "1", session_name: "1:1 Session" },
      { _id: "2", session_name: "Career Guidance" },
    ],
    reviews: [
      {
        name: "Kavya Nair",
        comment:
          "Navcharan turned my mediocre resume into high performing one. His mock interviews boosted my confidence and I got my first digital marketing role!",
        star: 5,
        position: "Junior Web Developer",
        company: null,
        linkedInUrl: null,
      },
      {
        name: "Neha Deshmukh",
        comment:
          "Navcharan’s career coaching and interview preparation sessions were on point. If you are a marketing fresher, you shouldn’t miss his mentorship sessions",
        star: 4,
        position: "Web developer",
        company: null,
        linkedInUrl: "https://www.linkedin.com/in/anotherperson",
      },
    ],
  },

  {
    _id: 3,
    mentor: {
      name: "Vikash Gaurav",
      linkedIn: "https://www.linkedin.com/in/vikash-gaurav-616086139",
      profilePhoto: VikashProfile,
      experience: [
        { role: "Sr. Backend Engineer", companyName: "Novo" },
        { role: "Software Engineer", companyName: "Quovantis" },
        { role: "Developer", companyName: "Fluper" },
      ],
      about:
        "Experienced FullStack Developer with a demonstrated history of working in the information technology and services industry. Skilled in JavaScript, Node.js, Angular, AWS.",
      username: "vikashgaurav",
    },
    sessions: [
      { _id: "1", session_name: "1:1 Session" },
      { _id: "2", session_name: "Career Guidance" },
      { _id: "3", session_name: "Mock Interviews" },
    ],
    reviews: [
      {
        name: "Neha Deshmukh",
        comment:
          "I went from being a college grad with no skill to a software developer with one thanks to Vikash's advice on career roadmap and resume. The coding world is a very competitive one and for freshers his guidance can be highly invaluable.",
        star: 5,
        position: "Junior Web Developer",
        company: null,
        linkedInUrl: null,
      },
      {
        name: "Vikram Singh",
        comment:
          "Vikash's feedback related to my first projects and even my resume was excellent and targeted, and I was able to get my first dev job. I would call him as the mentor that every coding fresher would require!",
        star: 5,
        position: "FUll Stack Developer",
        company: null,
        linkedInUrl: null,
      },
    ],
  },
  // {
  //   mentor: {
  //     name: "Kishan Kumar Ghedia",
  //     linkedIn: "https://www.linkedin.com/in/kishankumarghedia",
  //     profilePhoto: "URL to Kishan's photo",
  //     experience: [
  //       { role: "Sr. Product Manager", companyName: "ICICI Bank" },
  //       { role: "Sr. Product Manager", companyName: "Mantri Suzuki" },
  //       { role: "Sr. Product Manager", companyName: "RBL Bank" },
  //     ],
  //     about:
  //       "Currently leading the development of a low-code SaaS Conversation platform designed to transform banking relationships through highly personalized financial engagement across channels at ICICI Bank.",
  //     username: "kishan_ghedia",
  //   },
  //   sessions: [
  //     { _id: "1", session_name: "1:1 Session" },
  //     { _id: "2", session_name: "Mock Interview" },
  //     { _id: "3", session_name: "Career Guidance" },
  //   ],
  // },
  // {
  //   mentor: {
  //     name: "Vishwa Vijay Rana",
  //     linkedIn: "https://www.linkedin.com/in/vishwavijayrana",
  //     profilePhoto: "URL to Vishwa's photo",
  //     experience: [
  //       { role: "Marketing Manager", companyName: "MODIFI" },
  //       { role: "Marketing Specialist", companyName: "Gamma Marketing" },
  //     ],
  //     about:
  //       "A driven marketing professional with a focus on Account-Based Marketing (ABM) and a proven track record in B2B marketing strategies.",
  //     username: "vishwa_vijay_rana",
  //   },
  //   sessions: [
  //     { _id: "1", session_name: "Mock Interview" },
  //     { _id: "2", session_name: "Career Guidance" },
  //   ],
  // },
  // {
  //   mentor: {
  //     name: "Abhishek Rajan",
  //     linkedIn: "https://www.linkedin.com/in/abhishek-rajan", // Adjusted for LinkedIn URL pattern
  //     profilePhoto: "/path-to-abhishek-image.png", // Replace with actual image path
  //     experience: [
  //       { role: "Founder & CTO", companyName: "Unispade" },
  //       { role: "IT Specialist", companyName: "FutureXSkills" },
  //     ],
  //     about:
  //       "Diligent IT professional specializing in various technologies within the startup industry. Skilled in SCRUM, full-stack development, database management, and architecture design.",
  //     username: "abhishek_rajan", // Create a unique username based on the name
  //   },
  //   sessions: [
  //     { _id: "4", session_name: "1:1 Session" },
  //     { _id: "5", session_name: "Career Guidance" },
  //     { _id: "6", session_name: "Mock Interviews" },
  //   ],
  // },
];

export const MentorsDataForLineUp = {
  product_management: [
    {
      mentor: {
        name: "Kishan Kumar Ghedia",
        linkedIn: "https://www.linkedin.com/in/kishan-ghediya/",
        profilePhoto: KishanProfile,
        experience: [
          { role: "Sr. Product Manager", companyName: "ICICI Bank" },
          { role: "Sr. Product Manager", companyName: "Mantri Suzuki" },
          { role: "Sr. Product Manager", companyName: "RBL Bank" },
        ],
        about:
          "Currently leading the development of a low-code SaaS Conversation platform designed to transform banking relationships through highly personalized financial engagement across channels at ICICI Bank.",
        username: "kishan",
      },
      sessions: [
        { _id: "1", session_name: "1:1 Session" },
        { _id: "2", session_name: "Mock Interview" },
        { _id: "3", session_name: "Career Guidance" },
      ],
    },
    {
      mentor: {
        name: "Prasham Mehta",
        linkedIn: "https://www.linkedin.com/in/prasham-mehta/",
        profilePhoto: PrashamProfile,
        experience: [
          { role: "Product Manager", companyName: "123 Stores" },
          { role: "Product Manager", companyName: "North Europe Assistance" },
        ],
        about:
          "With over five years of experience in e-commerce and automotive industries, I thrive on transforming complex challenges into streamlined solutions. Currently, I lead key initiatives...",
        username: "prasham1895",
      },
      sessions: [
        { _id: "1", session_name: "1:1 Session" },
        { _id: "2", session_name: "Mock Interview" },
        { _id: "3", session_name: "Career Guidance" },
      ],
      reviews: [
        {
          name: "Mohit Dhakite",
          comment:
            "Prashant's mock interviews were an absolute game changer! The insider tips and the difficult questions made me ready for my PM role.",
          star: 5,
          position: "Junior Web Developer",
          company: null,
          linkedInUrl: null,
        },
        {
          name: "Mohit Dhakite",
          comment:
            "From a lost fresher to a confident product manager, all thanks to Prashant! His resume rebuilding and career plan were lifesavers. He is the mentor every PM aspirant needs!",
          star: 5,
          position: "Junior Web Developer",
          company: null,
          linkedInUrl: null,
        },
      ],
    },
  ],
  marketing: [
    {
      mentor: {
        name: "Navcharan Singh",
        linkedIn: "https://www.linkedin.com/in/navcharansinghbali/",
        profilePhoto: NavcharanProfile,
        experience: [
          { role: "Assistant Manager", companyName: "Times Network" },
          { role: "SEO Specialist", companyName: "Real Time Data Services" },
        ],
        about:
          "Experienced Digital Marketer with a history of working in the astrology, education & retail industry. Skilled in Search Engine Optimization (SEO), Google Ads, Google Analytics, and Email Marketing.",
        username: "Navcharan",
      },
      sessions: [
        { _id: "1", session_name: "1:1 Session" },
        { _id: "2", session_name: "Career Guidance" },
      ],
      reviews: [
        {
          name: "Mohit Dhakite",
          comment: "...",
          star: 5,
          position: "Junior Web Developer",
          company: null,
          linkedInUrl: null,
        },
        {
          name: "Another Person",
          comment: "...",
          star: 4,
          position: "Senior Marketing Analyst",
          company: "XYZ Corporation",
          linkedInUrl: "https://www.linkedin.com/in/anotherperson",
        },
      ],
    },
    {
      mentor: {
        name: "Vishwa Vijay Rana",
        linkedIn: "https://www.linkedin.com/in/ranavishwavijay/",
        profilePhoto:
          "https://unispade-s3.blr1.digitaloceanspaces.com/unispade-s3/1726416091849",
        experience: [
          { role: "Marketing Manager", companyName: "MODIFI" },
          { role: "Marketing Specialist", companyName: "Gamma Marketing" },
        ],
        about:
          "A driven marketing professional with a focus on Account-Based Marketing (ABM) and a proven track record in B2B marketing strategies.",
        username: "vishwavijay",
      },
      sessions: [
        { _id: "1", session_name: "Mock Interview" },
        { _id: "2", session_name: "Career Guidance" },
      ],
    },
  ],
  software_engineering: [
    {
      mentor: {
        name: "Vikash Gaurav",
        linkedIn: "https://www.linkedin.com/in/vikash-gaurav-616086139",
        profilePhoto: VikashProfile,
        experience: [
          { role: "Sr. Backend Engineer", companyName: "Novo" },
          { role: "Software Engineer", companyName: "Quovantis" },
          { role: "Developer", companyName: "Fluper" },
        ],
        about:
          "Experienced FullStack Developer with a demonstrated history of working in the information technology and services industry. Skilled in JavaScript, Node.js, Angular, AWS.",
        username: "vikashgaurav",
      },
      sessions: [
        { _id: "1", session_name: "1:1 Session" },
        { _id: "2", session_name: "Career Guidance" },
        { _id: "3", session_name: "Mock Interviews" },
      ],
      reviews: [
        {
          name: "Mohit Dhakite",
          comment:
            "I went from being a college grad with no skill to a software developer with one thanks to Vikash's advice on career roadmap and resume. The coding world is a very competitive one and for freshers his guidance can be highly invaluable.",
          star: 5,
          position: "Junior Web Developer",
          company: null,
          linkedInUrl: null,
        },
        {
          name: "Mohit Dhakite",
          comment:
            "Vikash's feedback related to my first projects and even my resume was excellent and targeted, and I was able to get my first dev job. I would call him as the mentor that every coding fresher would require!",
          star: 5,
          position: "Junior Web Developer",
          company: null,
          linkedInUrl: null,
        },
      ],
    },
    {
      mentor: {
        name: "Abhishek Rajan",
        linkedIn: "https://www.linkedin.com/in/abhishek-rajan-0027b4188/",
        profilePhoto:
          "https://unispade-s3.blr1.digitaloceanspaces.com/unispade-s3/1726538952113",
        experience: [
          { role: "Founder & CTO", companyName: "Unispade" },
          { role: "IT Specialist", companyName: "FutureXSkills" },
        ],
        about:
          "Diligent IT professional specializing in various technologies within the startup industry. Skilled in SCRUM, full-stack development, database management, and architecture design.",
        username: "abhishek",
      },
      sessions: [
        { _id: "4", session_name: "1:1 Session" },
        { _id: "5", session_name: "Career Guidance" },
        { _id: "6", session_name: "Mock Interviews" },
      ],
    },
  ],
};

export const AllMarketingMentors = [
  {
    mentor: {
      name: "Vikash Gaurav",
      linkedIn: "https://www.linkedin.com/in/vikash-gaurav-616086139",
      profilePhoto: VikashProfile,
      experience: [
        { role: "Sr. Backend Engineer", companyName: "Novo" },
        { role: "Software Engineer", companyName: "Quovantis" },
        { role: "Developer", companyName: "Fluper" },
      ],
      about:
        "Experienced FullStack Developer with a demonstrated history of working in the information technology and services industry. Skilled in JavaScript, Node.js, Angular, AWS.",
      username: "vikashgaurav",
    },
    sessions: [
      { _id: "1", session_name: "1:1 Session" },
      { _id: "2", session_name: "Career Guidance" },
      { _id: "3", session_name: "Mock Interviews" },
    ],
    reviews: [
      {
        name: "Mohit Dhakite",
        comment:
          "I went from being a college grad with no skill to a software developer with one thanks to Vikash's advice on career roadmap and resume. The coding world is a very competitive one and for freshers his guidance can be highly invaluable.",
        star: 5,
        position: "Junior Web Developer",
        company: null,
        linkedInUrl: null,
      },
      {
        name: "Mohit Dhakite",
        comment:
          "Vikash's feedback related to my first projects and even my resume was excellent and targeted, and I was able to get my first dev job. I would call him as the mentor that every coding fresher would require!",
        star: 5,
        position: "Junior Web Developer",
        company: null,
        linkedInUrl: null,
      },
    ],
  },
  {
    mentor: {
      name: "Abhishek Rajan",
      linkedIn: "https://www.linkedin.com/in/abhishek-rajan-0027b4188/",
      profilePhoto:
        "https://unispade-s3.blr1.digitaloceanspaces.com/unispade-s3/1726538952113",
      experience: [
        { role: "Founder & CTO", companyName: "Unispade" },
        { role: "IT Specialist", companyName: "FutureXSkills" },
      ],
      about:
        "Diligent IT professional specializing in various technologies within the startup industry. Skilled in SCRUM, full-stack development, database management, and architecture design.",
      username: "abhishek",
    },
    sessions: [
      { _id: "4", session_name: "1:1 Session" },
      { _id: "5", session_name: "Career Guidance" },
      { _id: "6", session_name: "Mock Interviews" },
    ],
  },
  {
    mentor: {
      name: "Navcharan Singh",
      linkedIn: "https://www.linkedin.com/in/navcharansinghbali/",
      profilePhoto: NavcharanProfile,
      experience: [
        { role: "Assistant Manager", companyName: "Times Network" },
        { role: "SEO Specialist", companyName: "Real Time Data Services" },
      ],
      about:
        "Experienced Digital Marketer with a history of working in the astrology, education & retail industry. Skilled in Search Engine Optimization (SEO), Google Ads, Google Analytics, and Email Marketing.",
      username: "Navcharan",
    },
    sessions: [
      { _id: "1", session_name: "1:1 Session" },
      { _id: "2", session_name: "Career Guidance" },
    ],
    reviews: [
      {
        name: "Mohit Dhakite",
        comment: "...",
        star: 5,
        position: "Junior Web Developer",
        company: null,
        linkedInUrl: null,
      },
      {
        name: "Another Person",
        comment: "...",
        star: 4,
        position: "Senior Marketing Analyst",
        company: "XYZ Corporation",
        linkedInUrl: "https://www.linkedin.com/in/anotherperson",
      },
    ],
  },
  {
    mentor: {
      name: "Vishwa Vijay Rana",
      linkedIn: "https://www.linkedin.com/in/ranavishwavijay/",
      profilePhoto:
        "https://unispade-s3.blr1.digitaloceanspaces.com/unispade-s3/1726416091849",
      experience: [
        { role: "Marketing Manager", companyName: "MODIFI" },
        { role: "Marketing Specialist", companyName: "Gamma Marketing" },
      ],
      about:
        "A driven marketing professional with a focus on Account-Based Marketing (ABM) and a proven track record in B2B marketing strategies.",
      username: "vishwavijay",
    },
    sessions: [
      { _id: "1", session_name: "Mock Interview" },
      { _id: "2", session_name: "Career Guidance" },
    ],
  },
  {
    mentor: {
      name: "Kishan Kumar Ghedia",
      linkedIn: "https://www.linkedin.com/in/kishan-ghediya/",
      profilePhoto: KishanProfile,
      experience: [
        { role: "Sr. Product Manager", companyName: "ICICI Bank" },
        { role: "Sr. Product Manager", companyName: "Mantri Suzuki" },
        { role: "Sr. Product Manager", companyName: "RBL Bank" },
      ],
      about:
        "Currently leading the development of a low-code SaaS Conversation platform designed to transform banking relationships through highly personalized financial engagement across channels at ICICI Bank.",
      username: "kishan",
    },
    sessions: [
      { _id: "1", session_name: "1:1 Session" },
      { _id: "2", session_name: "Mock Interview" },
      { _id: "3", session_name: "Career Guidance" },
    ],
  },
  {
    mentor: {
      name: "Prasham Mehta",
      linkedIn: "https://www.linkedin.com/in/prasham-mehta/",
      profilePhoto: PrashamProfile,
      experience: [
        { role: "Product Manager", companyName: "123 Stores" },
        { role: "Product Manager", companyName: "North Europe Assistance" },
      ],
      about:
        "With over five years of experience in e-commerce and automotive industries, I thrive on transforming complex challenges into streamlined solutions. Currently, I lead key initiatives...",
      username: "prasham1895",
    },
    sessions: [
      { _id: "1", session_name: "1:1 Session" },
      { _id: "2", session_name: "Mock Interview" },
      { _id: "3", session_name: "Career Guidance" },
    ],
    reviews: [
      {
        name: "Mohit Dhakite",
        comment:
          "Prashant's mock interviews were an absolute game changer! The insider tips and the difficult questions made me ready for my PM role.",
        star: 5,
        position: "Junior Web Developer",
        company: null,
        linkedInUrl: null,
      },
      {
        name: "Mohit Dhakite",
        comment:
          "From a lost fresher to a confident product manager, all thanks to Prashant! His resume rebuilding and career plan were lifesavers. He is the mentor every PM aspirant needs!",
        star: 5,
        position: "Junior Web Developer",
        company: null,
        linkedInUrl: null,
      },
    ],
  },
];
