import React from "react";
import "./Singtextbutton.css";

const Singletextbutton = ({ content, onClick, width }) => {
  const buttonStyle = {
    width: `${width ? `${width}px` : "max-content"}`,
    padding: `0 ${width ? "0" : "20px"}`,
  };
  return (
    <button
      style={buttonStyle}
      onClick={onClick}
      className="Singlebuttoncomponent"
    >
      <span className="Singlebuttoncomponent-text">{content}</span>
    </button>
  );
};

export default Singletextbutton;
