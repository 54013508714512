import React from "react";
import "./Unispadeadvantage.css";
import Singletextbutton from "../atoms/Singletextbutton";
import { useHistory } from "react-router-dom";

const data = [
  {
    advantageHeading: "Only the best mentors",
    advantageDesc:
      "Carefully vetted mentors, ensuring you connect with true industry leaders",
    sessions: [
      { _id: "1", session_name: "Handpicked Mentors" },
      { _id: "2", session_name: "> 5 years of experience" },
      { _id: "3", session_name: "Working at recognized startups" },
    ],
  },
  {
    advantageHeading: 'No more "No-shows"',
    advantageDesc:
      "A robust scheduling system and a strict attendance policy for all mentors",
    sessions: [
      // Assuming you want sessions here too, you can add them
      { _id: "7", session_name: "Unispade credits in case of “no-shows”" },
      { _id: "8", session_name: "Reschedule in case of unavailability" },
    ],
  },
  {
    advantageHeading: "Guaranteed interview",
    advantageDesc:
      "If you're a part of a mentor's talent pool, you should expect an interview within 2 weeks",
    sessions: [
      // Assuming you want sessions here too, you can add them
      { _id: "9", session_name: "Endorsement by mentor" },
      { _id: "10", session_name: "15X higher chance of interview" },
    ],
  },
  {
    advantageHeading: "24x7 Support",
    advantageDesc:
      "Our dedicated support team is always ready to assist you in case you face any issues",
    sessions: [
      // Assuming you want sessions here too, you can add them
      { _id: "11", session_name: "Address technical glitches" },
      { _id: "12", session_name: "Uninterrupted consultations" },
    ],
  },
];
const Unispadeadvantage = () => {
  const history = useHistory();

  const redirectToMentorSearch = () => {
    history.push("/mentors");
  };
  return (
    <div className="UnispageadvantageContainer">
      <div className="second-main-heading">The Unispade Advantage</div>

      <div className="AdvantagesList">
        {data?.map((element, index) => {
          return (
            <div className="SingleAdvantageContainer">
              <div className="component-sub-heading-first-line">
                {element.advantageHeading}
              </div>

              <div className="component-heading-description-light">
                {element.advantageDesc}
              </div>

              <div className="FeaturesList">
                {element?.sessions?.map((session) => {
                  return (
                    <div className="Feature" key={session._id}>
                      {session?.session_name}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <Singletextbutton
        content={"Connect with a mentor now"}
        onClick={redirectToMentorSearch}
      />
    </div>
  );
};

export default Unispadeadvantage;
