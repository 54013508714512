import Faqs from "./compoments/Faqs/Faqs";
import Hero from "./compoments/Hero/Hero";
import Howitworks from "./compoments/Howitworks/Howitworks";

import Mentorlineup from "./compoments/Mentorlineup/Mentorlineup";
import Unispadeadvantage from "./compoments/Unispadeadvantage/Unispadeadvantage";
import { Helmet } from "react-helmet-async";
import "./MentorProductPage.css";
import WhyChooseUnispade from "./compoments/WhyChooseUnispade/WhyChooseUnispade";
function MentorProductPage() {
  return (
    <div className="MentorProductPage">
      <Helmet>
        <title>
          Find & Hire Expert Product Management & Design Mentors Online
        </title>
        <meta
          name="description"
          content="Connect with expert product management and design mentors online. Get tailored guidance to accelerate your career and drive success in your projects. Register Now"
        />
        <link rel="canonical" href="https://unispade.com/mentor/products" />
      </Helmet>
      <Hero />
      <Howitworks />
      <Mentorlineup />
      <WhyChooseUnispade />
      <Unispadeadvantage />
      {/* <Knowledgesection /> */}
      <Faqs />
    </div>
  );
}

export default MentorProductPage;
