import React, { useState } from "react";
import "./TwoButtonWrapper.css";

const TwoButtonWrapper = ({
  firstComponent,
  secondComponent,
  firstComponentFunction,
  secondComponentFunction,
  componentFunction,
  backgroundColor,
  color,
  borderColor,
  hoverActivate,
  background,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const mainClass = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    minHeight: props?.height || "60px",
    padding: props?.padding || "0 20px",
    // backgroundColor: backgroundColor || "black",
    color: color || "white",
    border: "1px solid",
    borderColor: borderColor || "#E0E0E0",
    borderRadius: props?.borderRadius || "20px",
    fontSize: props?.fontSize || "18px",
    cursor: "pointer",
    width: "max-content",
    background: background
      ? background
      : backgroundColor
      ? "none"
      : "defaultBackground",
    backgroundColor: backgroundColor || (background ? "none" : "black"),

    // Add these properties for better word handling
    wordBreak: "break-word", // Break long words
    overflowWrap: "break-word", // Break words when necessary
    whiteSpace: "normal", // Allow wrapping of text
    maxWidth: "100%", // Restrict element to parent width
    textAlign: "left", // Center text
    lineHeight: "18px",
  };

  return (
    <div
      style={mainClass}
      onClick={componentFunction}
      onMouseEnter={props?.onMouseEnter}
      onMouseLeave={props?.onMouseLeave || (() => setHover(false))}
    >
      {hoverActivate ? (
        <>
          <div
            onClick={firstComponentFunction}
            onMouseEnter={() => setHover(true)}
          >
            {firstComponent}
          </div>

          {hover && (
            <div onClick={secondComponentFunction}>{secondComponent}</div>
          )}
        </>
      ) : (
        <>
          <div onClick={firstComponentFunction}>{firstComponent}</div>
          {secondComponent && (
            <div onClick={secondComponentFunction}>{secondComponent}</div>
          )}
        </>
      )}
    </div>
  );
};

export default TwoButtonWrapper;
