import React from "react";
import ProfilePhoto from "../../Images/profile.png";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import "./Singlementorcard.css";
import { useHistory } from "react-router-dom";
// const element = {
//   mentor: {
//     name: "John Doe",
//     linkedIn: "https://www.linkedin.com/in/johndoe",
//     experience: [
//       { role: "Software Engineer", companyName: "Google" },
//       { role: "Senior Developer", companyName: "Microsoft" },
//       { role: "Software Engineer", companyName: "Google" },
//       { role: "Senior Developer", companyName: "Microsoft" },
//       { role: "Software Engineer", companyName: "Google" },
//       { role: "Senior Developer", companyName: "Microsoft" },
//     ],
//     about:
//       "John is a passionate mentor with over 10 years of experience in software development. He loves guiding young developers to reach their potential.John is a passionate mentor with over 10 years of experience in software development. He loves guiding young developers to reach their potential.John is a passionate mentor with over 10 years of experience in software development. He loves guiding young developers to reach their potential.",
//     username: "john_doe",
//   },
//   sessions: [
//     { _id: "1", session_name: "React Basics" },
//     { _id: "2", session_name: "Advanced JavaScript" },
//     { _id: "3", session_name: "React Basics" },
//   ],
// };

const redirectToParticularSession = (username, sessionId) => {
  alert(`Redirecting to ${username}'s session with ID: ${sessionId}`);
};

const redirectToProfile = (username) => {
  alert(`Redirecting to profile of ${username}`);
};

const redirectToSessionBooking = (username) => {
  alert(`Redirecting to book a session for ${username}`);
};
const Singlementorcard = ({ element }) => {
  const history = useHistory();

  const redirectToMentorSearch = () => {
    history.push(`/userprofile/${element?.mentor?.username}`);
  };
  return (
    <div className="Singlementorcard">
      <img
        src={element?.mentor?.profilePhoto || ProfilePhoto}
        alt=""
        style={{
          width: "70px",
          height: "70px",
          borderRadius: "50%",
        }}
      />
      <div className="nameAndRole">
        <p className="mentorName">{element?.mentor?.name}</p>
        <p className="mentorRole">
          <p>{element?.mentor?.experience[0]?.role}</p>
          <p>
            at <strong>{element?.mentor?.experience[0]?.companyName}</strong>{" "}
          </p>
        </p>
      </div>

      <div className="companiesAssociated">
        {element?.mentor?.experience.map((exp) => {
          {
            return <p className="companyName">{exp?.companyName}</p>;
          }
        })}
      </div>

      <div className="aboutPara">{element?.mentor?.about}</div>

      <div className="sessionList">
        {element?.sessions?.map((session) => {
          return (
            <TwoButtonWrapper
              firstComponent={session?.session_name}
              secondComponent={
                <span class="material-symbols-outlined">call_made</span>
              }
              secondComponentFunction={() => redirectToMentorSearch()}
              backgroundColor={"white"}
              color={"black"}
              height={"40px"}
            />
          );
        })}
      </div>

      <div className="sessionButtons">
        <TwoButtonWrapper
          firstComponent={"View Profile"}
          componentFunction={() => redirectToMentorSearch()}
          backgroundColor={"#f7f7f7"}
          color={"black"}
          height={"35px"}
          borderRadius={"10px"}
          fontSize={"12px"}
          padding={"0 10px"}
          borderColor={"#f7f7f7"}
        />

        <TwoButtonWrapper
          firstComponent={"Book a session"}
          componentFunction={() => redirectToMentorSearch()}
          height={"35px"}
          borderRadius={"10px"}
          fontSize={"12px"}
          padding={"0 10px"}
          color={"white"}
          backgroundColor={"black"}
        />
      </div>
    </div>
  );
};

export default Singlementorcard;
