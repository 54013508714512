import React from "react";
import "./Footer.css";
import { Link, useHistory } from "react-router-dom";
import { Redirect } from "../HomePageComponents/images";
import { UnispadeBlack } from "../HomePageComponents/images/index";

function Footer(props) {
  const history = useHistory();
  const loginHandler = () => {
    if (localStorage.getItem("user") !== null) {
      history.push("./adashboard/aeditprofile");
    } else {
      history.push("./login");
    }
  };
  return (
    <div>
      <footer className="footer">
        <div className="title">
          <img
            onClick={() => history.push("/")}
            src={UnispadeBlack}
            alt="unispade_logo"
            className="logo"
          />
          <div className="buttonDiv">
            <a href="https://forms.gle/io3mjcjwLZgwuLqi9" target="_blank" rel="noopener noreferrer">
              <button className="exploreTalentBtn" style={{ width: "196px" }}>
                <p>For Talent</p>
                <img
                  src={Redirect}
                  alt="Explore talent icon"
                  width="20"
                  height="20"
                />
              </button>
            </a>
          </div>
        </div>
        <div className="footerSection">
          <div>
            <div className="explore-heading">Explore</div>
            <div className="explore-options">
              <a href="" target="_blank" rel="noopener noreferrer">Talent</a>
              <a href="" target="_blank" rel="noopener noreferrer">Jobs</a>
              <a href="" target="_blank" rel="noopener noreferrer">Projects</a>
              <a href="" target="_blank" rel="noopener noreferrer">Pools</a>
              <Link
                to="/mentor/products"
                onClick={() => props.setSelectedNavbarTab("mentor-product")}
                target="_blank" rel="noopener noreferrer"
              >
                Product Mentors
              </Link>
            </div>
          </div>
          <div>
            <h4 className="explore-heading">About</h4>
            <div className="explore-options">
              <a href="https://blog.unispade.com/" target="_blank" rel="noopener noreferrer">
                Blog
              </a>
              <a href="/pricing/" target="_blank" rel="noopener noreferrer">Pricing</a>
              <a href="" target="_blank" rel="noopener noreferrer">Success Stories</a>
              <a href="" target="_blank" rel="noopener noreferrer">Changelog</a>
            </div>
          </div>
          <div>
            <h4 className="explore-heading">Quick links</h4>
            <div className="explore-options">
              <a href="/termsandconditions" target="_blank" rel="noopener noreferrer">Terms of Use</a>
              <a href="/termsandconditions" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              <a href="#" target="_blank" rel="noopener noreferrer">Cookies</a>
              <a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">Sitemap</a>
            </div>
          </div>
          <div>
            <h4 className="explore-heading contact">Contact</h4>
            <div className="explore-options contact">
              <a href="mailto:support@unispade.com" target="_blank" rel="noopener noreferrer">
                Mail: support@unispade.com
              </a>
              <a href="tel:+918318065217" target="_blank" rel="noopener noreferrer">Mobile: +91 8318065217</a>
            </div>
          </div>
        </div>
        <div className="mobile-bottom-line"></div>
      </footer>
    </div>
  );
}

export default Footer;