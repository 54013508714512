import React, { useEffect, useState } from "react";
import "./PoolAnalysis.css";
import axios from "../../../../axios";
import LocalPoolAnalysis from "./LocalPoolAnalysis.js";
import { Helmet } from "react-helmet-async";

const PoolAnalysis = () => {
  const [categoryArray, setCategoryArray] = useState();
  const [overAllCategoryData, SetOverAllCategoryData] = useState();
  const [localCategory, setLocalCategory] = useState(null);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `talentPool/get_data_for_analysis/${Marketer.user._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );
      console.log("The data return by get api is ", data);
      setCategoryArray(data?.detailedData);
      SetOverAllCategoryData(data?.overAllData);
    })();
  }, []);

  const selectLocalCategory = (category_id) => {
    const cate = categoryArray?.find((element) => element._id === category_id);
    console.log("the selected category is ", cate);
    setLocalCategory(cate);
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.unispade.com/user-dashboard/pool-analytics"
        />
      </Helmet>
      {localCategory ? (
        <LocalPoolAnalysis
          category_data={localCategory}
          setLocalCategory={setLocalCategory}
        ></LocalPoolAnalysis>
      ) : (
        <div className="poolAnalysisComponent">
          <div className="nameHeading">
            <h4>Universal Pool Analytics</h4>
          </div>
          <div className="selectDateRange">
            <select name="dateRange" id="date-select">
              <option value="1">1 Day</option>
              <option value="7">7 Days</option>
              <option value="30">30 Days</option>
            </select>
          </div>
          <div className="lineGraph"></div>
          <div className="overallPoolData">
            <div>
              <p>Total earnings</p>
              <h2>{overAllCategoryData?.net_earnings}</h2>
            </div>
            <div>
              <p>Total Views</p>
              <h2>{overAllCategoryData?.net_view}</h2>
            </div>
          </div>
          <div className="totalPools">
            <h6>Pool Stats</h6>
            <h6>{overAllCategoryData?.net_categories}</h6>
          </div>
          <div className="poolDataTable">
            <table className="categoryDetailTable">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Views</th>
                  <th>Unlocks</th>
                  <th>Earnings</th>
                </tr>
              </thead>
              <tbody>
                {categoryArray?.map((category) => (
                  <>
                    <tr key={category._id}>
                      <td onClick={() => selectLocalCategory(category._id)}>
                        <span>{category.name} | </span>
                        <span>{category.user_count}</span>
                      </td>
                      <td>
                        <div className="poolCategoryColumn">
                          <span>{category.views}</span>
                        </div>
                      </td>
                      <td>
                        <div className="poolCategoryColumn">
                          <span>{category.unlocks}</span>
                        </div>
                      </td>

                      <td>
                        <div className="poolCategoryColumn">
                          <span>{category.earnings}</span>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default PoolAnalysis;
