import React, { useEffect, useState } from "react";
import "./BankDetails.css";
import axios from "../../../axios";
import EditDetail from "./EditDetail";
import { Helmet } from "react-helmet-async";
export default function BankDetails() {
  const [bankDetails, setBankDetails] = useState({});
  const [existingData, setExistingData] = useState([]);
  const [bankDetailModal, setBankDetailModal] = useState(false);

  const [idToEdit, setIdToEdit] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [editableData, setEditableData] = useState();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/marketer-bank-detail/get/${Marketer?.user?._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer?.token,
          },
        }
      );
      setExistingData(data);
    })();
  }, []);

  const addBankDetail = async (e) => {
    e.preventDefault();
    const formElements = e.target.elements;
    const newBankDetails = {
      bank_name: formElements.bank_name.value,
      account_number: formElements.account_number.value,
      ifsc_code: formElements.ifsc_code.value,
      account_name: formElements.account_name.value,
      user_id: Marketer?.user?._id,
    };

    const { data } = await axios.post(
      "/marketer-bank-detail/create",
      newBankDetails,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    console.log("the post data is ", data);
    setExistingData((prevData) => [...prevData, data]);

    setBankDetailModal(false);
  };

  const deleteBankDetails = async (id) => {
    const { data } = await axios.delete(`/marketer-bank-detail/delete/${id}`, {
      headers: {
        authorization: "Bearer " + Marketer.token,
      },
    });

    const tempExistingData = existingData?.filter((e) => e._id !== id);
    setExistingData(tempExistingData);
  };

  const editBankDetails = async (id) => {
    const tempData = existingData?.filter((key) => key._id === id);
    setEditableData(tempData[0]);
    setIdToEdit(id);
    setEditModal(true);
  };

  return (
    <div className="bankDetailPage">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.unispade.com/user-dashboard/bank-details"
        />
      </Helmet>
      {editModal && (
        <EditDetail
          id={idToEdit}
          existingData={existingData}
          setExistingData={setExistingData}
          setEditModal={setEditModal}
          olderData={editableData}
        />
      )}
      {existingData?.map((data) => {
        return (
          <div className="existingDetails" key={data?._id}>
            <div className="singleDetailCard">
              <div>
                <span>Bank Name</span>
                <span className="info-fields">{data?.bank_name}</span>
              </div>
              <div>
                <span>Account Number</span>
                <span className="info-fields">{data?.account_number}</span>
              </div>
              <div>
                <span>IFSC Code</span>
                <span className="info-fields">{data?.ifsc_code}</span>
              </div>
              <div>
                <span>Account Name</span>
                <span className="info-fields">{data?.account_name}</span>
              </div>
            </div>
            <div className="existingDetailsButton">
              <button
                className="editDetailsButton"
                onClick={() => editBankDetails(data?._id)}
              >
                Edit
              </button>
              <button
                className="deleteDetailsButton"
                onClick={() => deleteBankDetails(data?._id)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      })}

      {bankDetailModal ? (
        <form action="" className="bankDetailForm" onSubmit={addBankDetail}>
          <div className="bankDetailGrid">
            <div className="grid-child">
              <label htmlFor="bank_name">Bank Name</label>
              <input type="text" name="bank_name" />
            </div>
            <div className="grid-child">
              <label htmlFor="account_number">Account Number</label>
              <input type="text" name="account_number" />
            </div>
            <div className="grid-child">
              <label htmlFor="ifsc_code">IFSC Code</label>
              <input type="text" name="ifsc_code" />
            </div>
            <div className="grid-child">
              <label htmlFor="account_name">Account Name</label>
              <input type="text" name="account_name" />
            </div>
          </div>
          <div className="bankDetailButtons">
            <button
              className="cancelButton"
              onClick={() => {
                setBankDetailModal(false);
              }}
            >
              Cancel
            </button>
            <button type="submit" className="saveButton">
              Save
            </button>
          </div>
        </form>
      ) : (
        <button
          className="addAccountButton"
          onClick={() => {
            setBankDetailModal(true);
          }}
        >
          Add your Account
        </button>
      )}
    </div>
  );
}
