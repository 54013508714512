import React, { useEffect, useState } from "react";
import "./Mentorlineup.css";
import Singletextbutton from "../atoms/Singletextbutton";
import Singlementorcard from "../Singlementorcard/Singlementorcard";
import { FaArrowRight } from "react-icons/fa";
import { MentorsDataForLineUp } from "../../home-page-sample-data";
import { useHistory } from "react-router-dom";
import { AllMarketingMentors } from "../../../NewHomePage/home-page-sample-data";

const MentorLineUpData = [
  {
    category: "Product Management",
    name: "product_management",
    description: [
      "Accelerate your product management career with insights from our top mentors, from the top companies around the globe.",
      "Get personalized guidance on:",
    ],
    guidancePoints: [
      "Crafting product roadmaps",
      "Stakeholder management",
      "Data-driven strategies",
      "Interview preparation",
    ],
    buttonText: "Product Management Mentors",
  },
  {
    category: "Marketing",
    name: "marketing",
    description: [
      "Our mentors, with extensive experience in SEO, SEM, and marketing automation, offer tailored advice to elevate your skills.",
      "Whether you're a digital marketing novice or looking to refine your strategy, gain insights on:",
    ],
    guidancePoints: [
      "Crafting effective digital campaigns",
      "Optimizing search performance",
      "Leveraging automation tools",
      "Measuring marketing ROI",
    ],
    buttonText: "Marketing Mentors",
  },
  {
    category: "Software Engineering",
    name: "software_engineering",
    description: [
      "Our mentors, with deep experience in various programming languages and development methodologies, offer personalized insights.",
      "Whether you're a coding novice or an experienced developer, gain knowledge on:",
    ],
    guidancePoints: [
      "Mastering in-demand programming languages",
      "Implementing efficient algorithms and data structures",
      "Adopting best practices in software architecture",
      "Excelling in technical interviews",
    ],
    buttonText: "Software Engineering Mentors",
  },
];

const Mentorlineup = () => {
  const [mentorsData, setMentorsData] = useState([]);
  const history = useHistory();

  const redirectToMentorSearch = () => {
    history.push("/mentors");
  };
  useEffect(() => {
    setMentorsData(AllMarketingMentors);
  }, []);

  return (
    <div className="MentorlineupContainer">
      <div className="second-main-heading">Our Mentor Lineup</div>

      <div className="CategoryProductMentorsList">
        {mentorsData?.map((element, index) => {
          return <Singlementorcard element={element} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Mentorlineup;
