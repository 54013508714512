import React, { useEffect, useState } from "react";
import behance from "../DemoImages/Individual Profile/behance1.png";
import dribble from "../DemoImages/Individual Profile/dribble1.png";
import facebook from "../DemoImages/Individual Profile/facebook1.png";
import instagram from "../DemoImages/Individual Profile/instagram1.png";
import linkedin from "../DemoImages/Individual Profile/linkedin1.png";
import pinterest from "../DemoImages/Individual Profile/pinterest1.png";
import telegram from "../DemoImages/Individual Profile/telegram1.png";
import tiktok from "../DemoImages/Individual Profile/tiktok1.png";
import twitter from "../DemoImages/Individual Profile/twitter1.png";
import whatsapp from "../DemoImages/Individual Profile/whatsapp1.png";
import youtube from "../DemoImages/Individual Profile/youtube1.png";
import Redirect from "../DemoImages/Individual Profile/arrow-top-right.svg";
import chevronDown from "../DemoImages/Individual Profile/chevron-right.svg";
import star from "../DemoImages/Individual Profile/star.svg";
import profilePicture from "../DemoImages/Individual Profile/profilePicture.svg";
import "./about.css";
import { Helmet } from "react-helmet-async";

const About = ({ user, caseStudies }) => {
  const location = window.location;
  // console.log(user);
  // let socialLinks=use
  const socalMediaLogos = [
    {
      iconsName: "behance",
      img: behance,
      link: user?.socialLinks?.behance,
    },
    {
      iconsName: "dribble",
      img: dribble,
      link: user?.socialLinks?.dribble,
    },
    {
      iconsName: "facebook",
      img: facebook,
      link: user?.socialLinks?.facebook,
    },
    {
      iconsName: "instagram",
      img: instagram,
      link: user?.socialLinks?.instagram,
    },
    {
      iconsName: "linkedin",
      img: linkedin,
      link: user?.socialLinks?.linkedIn,
    },
    {
      iconsName: "pinterest",
      img: pinterest,
      link: user?.socialLinks?.pinterest,
    },
    {
      iconsName: "telegram",
      img: telegram,
      link: user?.socialLinks?.telegram,
    },
    {
      iconsName: "tiktok",
      img: tiktok,
      link: user?.socialLinks?.tikTok,
    },
    {
      iconsName: "twitter",
      img: twitter,
      link: user?.socialLinks?.xProfile,
    },
    {
      iconsName: "whatsapp",
      img: whatsapp,
      link: user?.socialLinks?.whatsapp,
    },
    {
      iconsName: "youtube",
      img: youtube,
      link: user?.socialLinks?.youtube,
    },
  ];

  console.log(socalMediaLogos.length);

  const [otherLinks, setOtherLinks] = useState([]);

  useEffect(() => {
    if (user?.socialLinks && Array.isArray(user.socialLinks.otherLinks)) {
      setOtherLinks([...user.socialLinks.otherLinks]);
    } else {
      setOtherLinks([]); // Set an empty array if otherLinks is not an array
    }
  }, [user?.socialLinks]);

  console.log(user?.experience);

  return (
    <div className="userProfileAbout">
      {user.experience &&
        user.experience.map((experience) => {
          return (
            <div className="eachCompanyExperience">
              <div
                className="companyLogoContainer"
                style={{ display: "inline" }}
              >
                <img
                  src={experience?.companyLogo}
                  alt=""
                  style={{
                    width: "40px",
                    height: "40px",
                    display: "inline",
                    borderRadius: "50%",
                  }}
                />
              </div>

              <div className="writtenExperience">
                <div className="experience-item-header">
                  <div className="experience-item-title">
                    {experience?.role}
                  </div>
                  <div className="experience-item-company">
                    {experience?.companyName}
                  </div>
                  <div className="experience-item-duration">
                    {experience?.dates?.from?.month}{" "}
                    {experience?.dates?.from?.year} -{" "}
                    {experience?.dates?.till?.month === null &&
                    experience?.dates?.till?.year === null
                      ? "Present"
                      : experience?.dates?.till?.month +
                        " " +
                        experience?.dates?.till?.year}
                  </div>
                </div>
                <div className="experience-item-description">
                  <p>{experience?.description}</p>
                </div>
                {experience?.skills?.length > 0 && (
                  <div>
                    <p className="notableThingsHeading">Skills</p>
                    <div className="experienceNotableThings">
                      {experience?.skills?.map((skill) => {
                        return (
                          <div className="experienceFeatures">{skill}</div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {experience?.tools?.length > 0 && (
                  <div>
                    <p className="notableThingsHeading">Tools</p>
                    <div className="experienceNotableThings">
                      {experience?.tools?.map((tool) => {
                        return <div className="experienceFeatures">{tool}</div>;
                      })}
                    </div>
                  </div>
                )}

                {experience?.clients?.length > 0 && (
                  <div>
                    <p className="notableThingsHeading">Clients</p>
                    <div className="experienceNotableThings">
                      {experience?.clients?.map((client) => {
                        return (
                          <div className="experienceFeatures">
                            {client?.client_name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {experience?.projects?.length > 0 && (
                  <div>
                    <p className="notableThingsHeading">Projects</p>
                    <div className="experienceProjectsList">
                      {experience?.projects?.map((project) => {
                        return (
                          <div className="experienceProjects">
                            <div className="experienceProjectsTitle">
                              {project?.customer_name}
                            </div>
                            {project?.project_title && (
                              <div className="experienceProjectsAboutBox">
                                <div className="experienceProjectDescription">
                                  {project?.about}
                                </div>
                                {/* <span class="material-symbols-outlined">
                                arrow_outward
                              </span> */}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
{user.education && user.education.length > 0 &&
  user.education.map((educationElement) => {
    return (
      <div className="eachCompanyExperience" key={educationElement?.id}>
        <div className="writtenExperience">
          <div className="experience-item-header">
            {educationElement?.collegeDegree && (
              <div className="experience-item-title">
                {educationElement.collegeDegree}
              </div>
            )}
            {(educationElement?.collegeName || educationElement?.fieldOfStudy) && (
              <div className="experience-item-company">
                {educationElement?.collegeName}{" "}
                {educationElement?.collegeName && educationElement?.fieldOfStudy && "|"}{" "}
                {educationElement?.fieldOfStudy}
              </div>
            )}
            {(educationElement?.dates?.from || educationElement?.dates?.till || educationElement?.currently_pursuing) && (
              <div className="experience-item-duration">
                {educationElement?.dates?.from?.month}{" "}
                {educationElement?.dates?.from?.year} -{" "}
                {educationElement?.currently_pursuing
                  ? "Present"
                  : educationElement?.dates?.till?.month +
                    " " +
                    educationElement?.dates?.till?.year}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  })}
    </div>
    // <main className="aboutSection">
    //   <div className="aboutFirstSection">
    //     {/* social media icons */}
    //     <div className="socialMediaIcons" style={socalMediaLogos.length > 0 ? {display: 'flex'} : {display: 'none'}}>
    //       {socalMediaLogos.map((socialLink, index) => (
    //         <>
    //           {socialLink.link && (
    //             <a key={index} href={socialLink?.link} target="_blank" rel="noopener noreferrer">
    //               <img key={index} src={socialLink.img} alt="" />
    //             </a>
    //           )}
    //         </>
    //       ))}
    //     </div>

    //     {/* other links */}
    //     <div className="otherLinks" style={otherLinks.length > 0 ? {display: 'flex'} : {display: 'none'}}>
    //       {otherLinks?.map((link, index) => (
    //         <a key={index} href={link.linkUrl} target="_blank" rel="noopener noreferrer">
    //           <button>
    //             {link.linkTitle}
    //             &nbsp;
    //             <img src={Redirect} alt="" />
    //           </button>
    //         </a>
    //       ))}
    //     </div>

    //     <p className="aboutPara fontStart">
    //       {user?.about}
    //     </p>

    //     {/* <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
    //       <div className="reviewSection">
    //         <div className="stars">
    //           <img src={star} alt="" />
    //           <img src={star} alt="" />
    //           <img src={star} alt="" />
    //           <img src={star} alt="" />
    //           <img src={star} alt="" />
    //         </div>

    //         <p className="fontStart" style={{ padding: "10px 0" }}>
    //           Lorem ipsum, dolor sit amet consectetur adipisicing elit.
    //           Quibusdam ducimus maxime eveniet, esse adipisci possimus natus hic
    //           laudantium aliquam. Rerum!
    //         </p>

    //         <div className="profileSection">
    //           <img src={profilePicture} alt="" width="40px" />
    //           <div className="fontStart">
    //             <p>Sachin Dev</p>
    //             <span>Founder | Marketer | Growth enthusisat</span>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="reviewSection">
    //         <div className="stars">
    //           <img src={star} alt="" />
    //           <img src={star} alt="" />
    //           <img src={star} alt="" />
    //           <img src={star} alt="" />
    //           <img src={star} alt="" />
    //         </div>

    //         <p className="fontStart" style={{ padding: "10px 0" }}>
    //           Lorem ipsum, dolor sit amet consectetur adipisicing elit.
    //           Quibusdam ducimus maxime eveniet, esse adipisci possimus natus hic
    //           laudantium aliquam. Rerum!
    //         </p>

    //         <div className="profileSection">
    //           <img src={profilePicture} alt="" width="40px" />
    //           <div className="fontStart">
    //             <p>Sachin Dev</p>
    //             <span>Founder | Marketer | Growth enthusisat</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div> */}
    //   </div>

    //   <div className="aboutPageExperienceSection">
    //     <div className="aboutSecondSection">
    //       {user.experience ? (
    //         user.experience.map((experience, index) => (
    //           <div key={index} className="projectSectionContainer">
    //             <div>
    //               <img width={50} src={experience.companyLogo} alt="" />
    //             </div>
    //             <div className="projectSection">
    //               <p>
    //                 <span className="post">{experience.role}</span> at{" "}
    //                 <span className="company">{experience.companyName}</span>
    //               </p>
    //               <p className="datePeriod">
    //                 {experience?.dates?.from?.month} {experience?.dates?.from?.year} -{" "}
    //                 {experience?.dates?.till?.month} {experience?.dates?.till?.year}
    //               </p>
    //               <p>
    //                 View all {caseStudies?.length} projects at{" "}
    //                 <span className="company" style={{ fontWeight: "normal" }}>
    //                   {experience.companyName}
    //                 </span>
    //                 <img src={chevronDown} alt="" />
    //               </p>
    //               <p className="fontStart">{experience.description}</p>
    //             </div>
    //           </div>
    //         ))
    //       ) : (
    //         <div>No Experience</div>
    //       )}
    //       {/* {console.log(logosArray)} */}
    //     </div>
    //   </div>
    // </main>
  );
};

export default About;
