import React, { useEffect, useState } from "react";
import TalentPool from "./TalentPool/TalentPool";
import PoolCategory from "./PoolCategory/PoolCategory";
import "./Talent.css";
import axios from "../../../axios";
import { Helmet } from "react-helmet-async";

const Talent = () => {
  const [showInput, setShowInput] = useState(false);
  const [category, setCategory] = useState({
    name: "",
    price: "",
    accessLimit: "",
  });
  const [activeBtn, setActiveBtn] = useState("poolCategory");
  const [btns, setBtns] = useState([
    {
      id: "poolCategory",
      name: "Pool Category",
    },
  ]);

  const [categoryArray, setCategoryArray] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          `/talentPool/get_talent_pool_categories/${Marketer.user._id}`
        );
        if (data.length > 0) {
          setBtns((prev) => {
            return [...prev, { id: "talentPool", name: "Talent Pool" }];
          });
        }
        setCategoryArray(data);
      } catch (error) {
        console.log(error.response.status);
      }
    })();
  }, []);

  useEffect(() => {
    setActiveBtn("talentPool");
  }, [selectedCategory]);

  async function handleAddCategory() {
    try {
      setShowInput(false);
      const { data } = await axios.post(
        "/talentPool/create_talent_pool_categories",
        {
          name: category.name,
          price_per_user: category.price_per_user,
          accessLimit: category.accessLimit,
        },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );
      setCategoryArray((prev) => {
        return [...prev, data];
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.unispade.com/user-dashboard/talent"
        />
      </Helmet>
      <div>
        {activeBtn === "talentPool" && selectedCategory ? (
          <TalentPool
            selectedCategory={selectedCategory}
            setActiveBtn={setActiveBtn}
          />
        ) : (
          <PoolCategory
            categoryArray={categoryArray}
            setSelectedCategory={setSelectedCategory}
            setCategoryArray={setCategoryArray}
            category={category}
            setCategory={setCategory}
            showInput={showInput}
            setShowInput={setShowInput}
            handleAddCategory={handleAddCategory}
          />
        )}
      </div>
    </div>
  );
};

export default Talent;
